/** 审核状态 */
export const CHECK_STATUS = {
  NO: {
    value: 0,
    label: '未审核'
  },
  YES: {
    value: 1,
    label: '已审核'
  },
  INVALID: {
    value: 2,
    label: '作废'
  }
}
