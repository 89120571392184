<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>

        <el-button
          @click="onHandleCreate"
          type="primary"
          icon="el-icon-circle-plus-outline"
          >新增</el-button
        >
        <el-button
          @click="onHandleCreatebatch"
          type="primary"
          icon="el-icon-circle-plus-outline"
          >批量新增</el-button
        >
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.logistic_business_id"
          filterable
          clearable
          placeholder="集配中心"
        >
          <el-option
            v-for="item of logisticList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <!-- :filter-method="(data) => data && ajaxGetCityStoreList(data)"
        @visible-change="(data) => !data && ajaxGetCityStoreList" -->
        <el-select
          v-model="formData.logistics_id"
          @change="onChangeCityStore"
          :loading="loadingSelfLogisticsList"
          filterable
          clearable
          placeholder="城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          placeholder="自提点"
          v-model="formData.delivery_id"
          :loading="loadingSelfTakeList"
          clearable
        >
          <el-option
            v-for="item in selfTakeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="扣款日期起"
          end-placeholder="扣款日期止"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="dataTime"
          @change="changeDate"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-select
          placeholder="扣款类型"
          v-model="formData.deduction_type"
          clearable
        >
          <el-option
            v-for="item in deductionTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          placeholder="审核状态"
          v-model="formData.check_state"
          clearable
        >
          <el-option
            v-for="item in checkStateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <el-input v-model="formData.as_no" clearable placeholder="售后单号">
        </el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.trade_no"
          clearable
          placeholder="退款流水号"
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-select
          placeholder="扣款状态"
          v-model="formData.deduction_state"
          clearable
        >
          <el-option label="未扣款" value="0"> </el-option>
          <el-option label="已扣款 " value="1"> </el-option>
        </el-select>
      </div>
      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <section class="content">
      <!-- 列表 start -->
      <table-list
        ref="refTableList"
        v-loading="loadingData"
        :tableData="tableData"
        :isAudit="isAudit"
        @on-edit="onHandleEdit"
        @on-change="onHandleSearch('resetPage')"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 批量添加 start -->
    <batchdialog
      ref="refEditDialogbatch"
      @on-change="onHandleSearch('resetPage')"
      :deductionTypeList="deductionTypeListall"
    ></batchdialog>
    <!-- 批量添加 end -->
    <!-- 编辑，弹窗 start -->
    <edit-dialog
      ref="refEditDialog"
      @on-change="onHandleSearch('resetPage')"
      :deductionTypeList="deductionTypeAddList"
    ></edit-dialog>
    <!-- 编辑，弹窗 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import TableList from "./modules/table-list/index.vue";
import {
  getDeductionTypeList,
  getLogisticsDeductionList,
} from "@/api/general/operation-center/index";
import { postLogisticDeductionExport } from "@/api/export/center";
import EditDialog from "./modules/edit-dialog/index.vue";
import batchdialog from "./modules/batch-dialog/index.vue";
import { CHECK_STATUS } from "./utils/enum/index";
// import mock from "./mock.js";

export default {
  name: "CityDeductionManage",
  components: {
    TableList,
    EditDialog,
    batchdialog,
  },
  data() {
    return {
      loadingData: false,
      loadingSelfLogisticsList: false,
      loadingSelfTakeList: false,
      disabledExport: false,
      loadingCoupon: false,
      isAudit: false, // 是否有审核权限
      dataTime: [], // 时间对象
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      deductionTypeList: [], // 扣款类型下拉
      deductionTypeAddList: [], // 扣款新增下拉
      deductionTypeListall: [], // 扣款类型下拉
      checkStateList: Object.values(CHECK_STATUS), // 审核状态下拉
      formData: {
        logistic_business_id: "", // 集配中心id
        logistics_id: "", // 城市仓id
        delivery_id: "", // 自提点ID
        start_date: "", // 扣款日期起
        end_date: "", // 扣款日期止
        deduction_type: "", // 扣款类型
        check_state: "", // 审核状态
        as_no: "", // 售后单号
        trade_no: "", //退款流水号
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
    };
  },
  computed: {},
  watch: {
    /**
     * 如果集配中心发生改变，则重置城市仓数据
     */
    "formData.logistic_business_id"(val) {
      this.cityStoreList = [];
      this.ajaxGetCityStoreList(val);
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
      this.ajaxGetDeductionTypeList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingSelfLogisticsList = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingSelfLogisticsList = false;
      }
    }, 300),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function (logisticsId) {
      this.loadingSelfTakeList = true;
      const params = {
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      } finally {
        this.loadingSelfTakeList = false;
      }
    }, 300),
    /**
     * 获取扣款类型下拉列
     */
    async ajaxGetDeductionTypeList() {
      try {
        const { data } = await getDeductionTypeList();
        this.deductionTypeList = data.select;
        this.deductionTypeAddList = data.add;
        this.deductionTypeListall = [];
        data.select.forEach((el) => {
          if (el.id == 40 || el.id == 50 || el.id == 70) {
            this.deductionTypeListall.push(el);
          }
        });
        console.log("ajax getDeductionTypeList", data);
      } catch (err) {
        console.log("ajax getDeductionTypeList err", err);
      }
    },
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      this.getAjaxSelfTakeList(data);
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await getLogisticsDeductionList(params);
        const { total, data: list, is_audit } = data;
        this.total = total;
        this.tableData = list;
        this.isAudit = is_audit;
      } catch (err) {
        console.log("ajax getLogisticsDeductionList err", err);
      } finally {
        this.loadingData = false;
        // 模拟数据
        // this.tableData = mock.tableData;
        // this.total = mock.total;
      }
    },
    /**
     * 日期发生了变化
     */
    changeDate(data) {
      this.formData.start_date = data?.[0] || "";
      this.formData.end_date = data?.[1] || "";
    },
    /**
     * 查询表单
     */
    onHandleSearch(type) {
      // 重置为第一页
      if (type === "resetPage") {
        this.formData.page = 1;
      }
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dataTime = [];
      this.deliveryList = [];
      this.ajaxGetTableData();
    },
    /**
     * 新增
     */
    onHandleCreate() {
      this.$refs.refEditDialog.onInitData();
    },
    /**
     * 新增批量
     */
    onHandleCreatebatch() {
      this.$refs.refEditDialogbatch.onInitData();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          user_name: username,
          token: sessionStorage.getItem("token"),
        };
        delete params.page;
        delete params.pageSize;
        await postLogisticDeductionExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postLogisticDeductionExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 操作了数据
     */
    onHandleEdit(data) {
      this.$refs.refEditDialog.onInitData(data);
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    .el-pagination {
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }
}
/** 下拉选择模态框样式 */
.select-popper-wrap {
  .el-select-dropdown {
    &__item {
      .content {
        max-width: 200px;
        @include ellipsis-lines;
      }
    }
  }
}
/** 操作按钮样式 */
.deduct,
.coupon {
  color: #fff;
  border: none;
}
.deduct {
  background-color: #c42c2c;
  &.disabled {
    background-color: rgba(#c42c2c, 0.3);
  }
}
.coupon {
  background-color: #409eff;
  &.disabled {
    background-color: rgba(#409eff, 0.3);
  }
}
</style>
