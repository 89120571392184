var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: {
                model: _vm.formData,
                "label-width": "150px",
                rules: _vm.formRules,
                loading: _vm.formLoading,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "城市仓：", prop: "logistics_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        loading: _vm.loadingSelfLogisticsList,
                        disabled: _vm.disabledData,
                        filterable: "",
                        clearable: "",
                        placeholder: "选择城市仓",
                      },
                      on: { change: _vm.onChangeCityStore },
                      model: {
                        value: _vm.formData.logistics_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "logistics_id", $$v)
                        },
                        expression: "formData.logistics_id",
                      },
                    },
                    _vm._l(_vm.cityStoreList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自提点：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "选择自提点",
                        loading: _vm.loadingSelfTakeList,
                        disabled: _vm.disabledData,
                        clearable: "",
                      },
                      model: {
                        value: _vm.formData.delivery_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "delivery_id", $$v)
                        },
                        expression: "formData.delivery_id",
                      },
                    },
                    _vm._l(_vm.selfTakeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "扣款周期：", prop: "start_date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "扣款日期起",
                      "end-placeholder": "扣款日期止",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      align: "right",
                      "picker-options": _vm.pickerOptions,
                      disabled: _vm.disabledData,
                    },
                    on: { change: _vm.changeDate },
                    model: {
                      value: _vm.dataTime,
                      callback: function ($$v) {
                        _vm.dataTime = $$v
                      },
                      expression: "dataTime",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.formData.deduction_data, function (item, i) {
                return _c("el-row", { key: i }, [
                  _c(
                    "div",
                    { staticClass: "positions" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "扣款类型：",
                            prop: `deduction_data.${i}.deduction_type`,
                            rules: _vm.DataRules.deduction_type,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择扣款类型",
                                disabled: _vm.disabledData,
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.typeChange($event, item, i)
                                },
                              },
                              model: {
                                value: item.deduction_type,
                                callback: function ($$v) {
                                  _vm.$set(item, "deduction_type", $$v)
                                },
                                expression: "item.deduction_type",
                              },
                            },
                            _vm._l(_vm.deductionTypeList, function (item1) {
                              return _c("el-option", {
                                key: item1.id,
                                attrs: { label: item1.name, value: item1.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "金额：",
                            prop: `deduction_data.${i}.deduction_amt`,
                            rules: _vm.DataRules.deduction_amt,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.disabledData,
                              placeholder: "输入金额",
                              clearable: "",
                            },
                            model: {
                              value: item.deduction_amt,
                              callback: function ($$v) {
                                _vm.$set(item, "deduction_amt", $$v)
                              },
                              expression: "item.deduction_amt",
                            },
                          }),
                        ],
                        1
                      ),
                      item.deduction_type === _vm.SALE_DEDUCTION_TYPE
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "售后单号：",
                                prop: `deduction_data.${i}.as_no`,
                                rules: _vm.DataRules.as_no,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.disabledData,
                                  placeholder: "输入售后单号",
                                  clearable: "",
                                },
                                model: {
                                  value: item.as_no,
                                  callback: function ($$v) {
                                    _vm.$set(item, "as_no", $$v)
                                  },
                                  expression: "item.as_no",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.disabledData
                        ? _c("p", { staticClass: "tooltip" }, [
                            _vm._v(
                              " 扣款类型为二次售后扣款且关联售后单号后将给用户线上退款，请谨慎操作 "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "position-subset" }, [
                        _vm.formData.deduction_data.length == i + 1
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus",
                              on: { click: _vm.addproduct },
                            })
                          : _vm._e(),
                        _vm.formData.deduction_data.length != 1
                          ? _c("i", {
                              staticClass: "el-icon-delete-solid",
                              on: {
                                click: function ($event) {
                                  return _vm.delproduct(i)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "输入备注",
                      maxlength: "120",
                      disabled: _vm.disabledData,
                      rows: 3,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.formData.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "remarks", $$v)
                      },
                      expression: "formData.remarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "扣款证明：" } },
                [
                  _c("b-file-upload", {
                    attrs: {
                      accepts: ["jpg", "jpeg", "png"],
                      limit: 1,
                      disabled: _vm.disabledData,
                    },
                    model: {
                      value: _vm.picList,
                      callback: function ($$v) {
                        _vm.picList = $$v
                      },
                      expression: "picList",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.disabledData
            ? _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取消"),
              ])
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.disabledSubmit },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }